// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar {
    background-color: #222222;
    /* height: 40px; */
    padding: 5px;
}


/* NavLink styling */
.nav-link {
    color: rgb(77, 77, 77); /* Change this color to your desired link color */
    text-decoration: none;
}
  
/* Active NavLink styling */
a.active {
color: rgb(0, 50, 120); 
}

a:hover {
    color: rgb(0, 60, 110);
}`, "",{"version":3,"sources":["webpack://./src/components/TopBar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;;AAGA,oBAAoB;AACpB;IACI,sBAAsB,EAAE,iDAAiD;IACzE,qBAAqB;AACzB;;AAEA,2BAA2B;AAC3B;AACA,sBAAsB;AACtB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".top-bar {\n    background-color: #222222;\n    /* height: 40px; */\n    padding: 5px;\n}\n\n\n/* NavLink styling */\n.nav-link {\n    color: rgb(77, 77, 77); /* Change this color to your desired link color */\n    text-decoration: none;\n}\n  \n/* Active NavLink styling */\na.active {\ncolor: rgb(0, 50, 120); \n}\n\na:hover {\n    color: rgb(0, 60, 110);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
